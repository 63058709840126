export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";
export const LOG_POLLING_INTERVAL = Number(
  process.env.REACT_APP_LOG_POLLING_INTERVAL || 1000
);
export const LIVE_POLLING_INTERVAL = Number(
  process.env.REACT_APP_LIVE_POLLING_INTERVAL || 10000
);
export const CLERK_PUBLISHABLE_KEY =
  process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
export const SPEED_NEGATIVE_DELTA = Number(
  process.env.REACT_APP_SPEED_NEGATIVE_DELTA || 1
);
export const ELEMENTS_ON_PAGE =
  (process.env.REACT_APP_DEFAULT_AMOUNT_ELEMENTS_ON_PAGE_PAGINATION &&
    +process.env.REACT_APP_DEFAULT_AMOUNT_ELEMENTS_ON_PAGE_PAGINATION) ||
  20;
