import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClerkProvider } from "@clerk/clerk-react";
import { ukUA } from "@clerk/localizations";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  Chart as ChartJS,
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  ArcElement,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Zoom from "chartjs-plugin-zoom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CLERK_PUBLISHABLE_KEY } from "./config";
import ProfileContextProvider from "./contexts/Profile";
import TimeContextProvider from "./contexts/Time";
import AppRouter from "./Router";

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  Zoom,
  ChartDataLabels
);

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const App: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  if (!CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing CLERK_PUBLISHABLE_KEY");
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ClerkProvider
        publishableKey={CLERK_PUBLISHABLE_KEY}
        navigate={(to) => navigate(to)}
        appearance={{
          elements: {
            logoBox: "d-flex w-100 justify-content-center h-100",
            formFieldRow__password: "h-auto opacity-100 pe-auto m-0",
          },
        }}
        localization={i18n.language === "ua" ? ukUA : undefined}
      >
        <ProfileContextProvider>
          <TimeContextProvider>
            <AppRouter />
          </TimeContextProvider>
        </ProfileContextProvider>
      </ClerkProvider>
    </QueryClientProvider>
  );
};

export default App;
