import { SignedIn, UserButton, useAuth } from "@clerk/clerk-react";
import cn from "classnames";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
// import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import styled from "styled-components";

import packageJSON from "../../package.json";
import LogoSrc, {
  ReactComponent as Logo,
} from "../assets/images/hopak_logo_horizontal.svg";

const MainWrapper = styled.main`
  width: 100%;
  height: 100vh;
  /* background-color: #000; */
  background-color: #f5f5f5;
  overflow-y: auto;
`;

const HopakLogoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  overflow: hidden;
  opacity: 0.1;
  /* background-image: url(${LogoSrc});
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 10%;
  animation: fly 10s infinite linear forwards alternate;

  @keyframes fly {
    from {
      background-position: calc(100% - 1rem) 1rem;
    }
    to {
      background-position: 1rem calc(100% - 1rem);
    }
  } */
`;

interface LayoutProps {
  withHeader?: boolean;
  withOverlay?: boolean;
  withFooter?: boolean;
  fullWidth?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  // withFooter,
  withHeader = true,
  withOverlay = false,
  fullWidth,
}) => {
  const { isSignedIn } = useAuth();
  // const { i18n } = useTranslation();

  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng);
  // };

  return (
    <MainWrapper>
      <Helmet titleTemplate="%s | Hopak" />
      <Container
        fluid={fullWidth}
        className={cn(
          {
            "user-select-none": withOverlay,
            "align-items-center": !isSignedIn,
            "justify-content-center": !isSignedIn,
          },
          "d-flex flex-column h-100"
        )}
      >
        {withHeader && (
          <SignedIn>
            <header className="d-flex flex-wrap align-items-center justify-content-between justify-content-md-between py-3 border-bottom">
              <NavLink
                to="/"
                title={`v${packageJSON.version}`}
                className="text-decoration-none text-dark lh-1 d-flex align-items-end gap-2"
              >
                <Logo height="1.5rem" />
                <span className="h6 lh-1 border-start mb-1 ps-2">
                  miroplast
                </span>
              </NavLink>
              {/* <div>
                <button type="button" onClick={() => changeLanguage("ua")}>
                  ua
                </button>
                <button type="button" onClick={() => changeLanguage("en")}>
                  en
                </button>
              </div> */}
              <UserButton afterSignOutUrl="/sign-in" />
            </header>
          </SignedIn>
        )}
        {withOverlay && <HopakLogoOverlay aria-label="hopak-overlay" />}
        <Outlet />
        {/* {withFooter && (
          <footer>
            <p className="text-center text-muted border-top">
              © 2023 soft4man | Software for Manufacture
            </p>
          </footer>
        )} */}
      </Container>
    </MainWrapper>
  );
};

export default Layout;
