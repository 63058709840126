import { PropsWithChildren, createContext, useCallback, useState } from "react";

export interface TimeContextType {
  syncTimeItems: { ms: number; id: string }[];
  setSyncTimeItemById: (id: string, ms: number) => void;
}

export const TimeContext = createContext<TimeContextType>({
  syncTimeItems: [],
  setSyncTimeItemById: () => {},
});

const TimeContextProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [syncTimeItems, setSyncTimeItems] = useState<
    TimeContextType["syncTimeItems"]
  >([]);

  const setSyncTimeItemById = useCallback(
    (id: string, ms: number) => {
      setSyncTimeItems((prevSyncTimeItems) => {
        const syncTimeItemIndex = prevSyncTimeItems.findIndex(
          (syncTimeItem) => syncTimeItem.id === id
        );
        if (syncTimeItemIndex === -1) {
          return [...prevSyncTimeItems, { id, ms }];
        }
        const newSyncTimeItems = [...prevSyncTimeItems];
        newSyncTimeItems[syncTimeItemIndex] = { id, ms };

        return newSyncTimeItems;
      });
    },
    [setSyncTimeItems]
  );

  return (
    <TimeContext.Provider value={{ syncTimeItems, setSyncTimeItemById }}>
      {children}
    </TimeContext.Provider>
  );
};

export default TimeContextProvider;
