import { PropsWithChildren, createContext, useState } from "react";

export interface ProfileContextType {
  selectedPlaceId?: number;
  setSelectedPlaceId: (id: number) => void;
}

export const ProfileContext = createContext<ProfileContextType>({
  setSelectedPlaceId: () => {},
});

const ProfileContextProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [activePlaceId, setActivePlaceId] =
    useState<ProfileContextType["selectedPlaceId"]>();

  return (
    <ProfileContext.Provider
      value={{
        selectedPlaceId: activePlaceId,
        setSelectedPlaceId: setActivePlaceId,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextProvider;
