import { SignIn, SignUp, SignedIn, SignedOut } from "@clerk/clerk-react";
import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "./containers/Layout";

const BrandsPage = lazy(() => import("./pages/Brands"));
const ColorsPage = lazy(() => import("./pages/Colors"));
const ControllersPage = lazy(() => import("./pages/Controllers"));
const DashboardPage = lazy(() => import("./pages/Dashboard"));
const DictionaryPage = lazy(() => import("./pages/Dictionary"));
const ExtrudersPage = lazy(() => import("./pages/Extruders"));
const GasketsPage = lazy(() => import("./pages/Gaskets"));
const GeometriesPage = lazy(() => import("./pages/Geometries"));
const IndexPage = lazy(() => import("./pages/IndexPage"));
const LaminationsPage = lazy(() => import("./pages/Laminations"));
const LinesPage = lazy(() => import("./pages/Lines"));
const LivePage = lazy(() => import("./pages/Live"));
const MaterialsPage = lazy(() => import("./pages/Materials"));
const PlacesPage = lazy(() => import("./pages/Places"));
const ProductionTypesPage = lazy(() => import("./pages/ProductTypes"));
const ReportsPage = lazy(() => import("./pages/Reports"));
const ShiftTypesPage = lazy(() => import("./pages/ShiftTypes"));
const SpeedsPage = lazy(() => import("./pages/Speeds"));
const ToolsPage = lazy(() => import("./pages/Tools"));
const WorkerRolesPage = lazy(() => import("./pages/WorkerRoles"));
const WorkersPage = lazy(() => import("./pages/Workers"));

const AuthorizedRouter: React.FC = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<IndexPage />} />
      <Route path="reports" element={<ReportsPage />} />
      <Route path="live" element={<LivePage />} />
      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="dictionary">
        <Route index element={<DictionaryPage />} />
        <Route path="color" element={<ColorsPage />} />
        <Route path="brand" element={<BrandsPage />} />
        <Route path="extruder" element={<ExtrudersPage />} />
        <Route path="geometry" element={<GeometriesPage />} />
        <Route path="tool" element={<ToolsPage />} />
        <Route path="controller" element={<ControllersPage />} />
        <Route path="shift-type" element={<ShiftTypesPage />} />
        <Route path="place" element={<PlacesPage />} />
        <Route path="production-type" element={<ProductionTypesPage />} />
        <Route path="line" element={<LinesPage />} />
        <Route path="gasket" element={<GasketsPage />} />
        <Route path="lamination" element={<LaminationsPage />} />
        <Route path="material" element={<MaterialsPage />} />
        <Route path="speed" element={<SpeedsPage />} />
        <Route path="worker-role" element={<WorkerRolesPage />} />
        <Route path="worker" element={<WorkersPage />} />
      </Route>
    </Route>
  </Routes>
);

const PublicRouter: React.FC = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Navigate to="/sign-in" />} />
      <Route
        path="sign-in"
        element={<SignIn routing="path" path="/sign-in" signUpUrl="/sign-up" />}
      />
      <Route
        path="sign-up"
        element={<SignUp routing="path" path="/sign-up" signInUrl="/sign-in" />}
      />
    </Route>
    <Route path="public" element={<Layout fullWidth withOverlay />}>
      <Route path="dashboard" element={<DashboardPage />} />
    </Route>
    <Route path="*" element={<Navigate to="/sign-in" />} />
  </Routes>
);

const AppRouter: React.FunctionComponent = () => (
  <>
    <SignedIn>
      <AuthorizedRouter />
    </SignedIn>
    <SignedOut>
      <PublicRouter />
    </SignedOut>
  </>
);

export default AppRouter;
